<template>
  <v-card
    class="data-table"
    :class="canBeSelected ? 'data-table--selectable' : ''"
    elevation="4"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="!itemsLoading ? items : []"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :server-items-length="itemsTotal"
      :class="itemsLoading ? 'v-data-table--loading' : ''"
    >
      <template v-if="itemsLoading" v-slot:body>
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <backups-table-item-skeleton-mobile v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <backups-table-sort-skeleton />
          <backups-table-item-skeleton />
        </template>
      </template>

      <template v-slot:header="{ isMobile, props, on }" v-if="!noData">
        <table-custom-sort
          v-if="!$vuetify.breakpoint.lgAndDown && !itemsLoading"
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          :canBeSelected="canBeSelected"
          :allSelected="allSelected"
          @selectAll="handleSelectAll"
          v-on="$listeners"
        />
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.select="{ item }"
      >
        <div>
          <checkbox
            @change="handleSelectItem(item)"
            :checked="checkedItems.includes(item.id)"
          />
        </div>
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.name="{ item }"
      >
        <span class="bold">{{ item.name }}</span>
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.directory="{ item }"
      >
        <v-icon
          v-if="item.directory"
          size="20"
          color="primary"
          class="ms-4"
        >$checkcircle</v-icon>
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.database="{ item }"
      >
        <v-icon
          v-if="item.database"
          size="20"
          color="primary"
          class="ms-4"
        >$checkcircle</v-icon>
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.date="{ item }"
      >
        <span v-html="formatDate(item.date)" />
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.type="{ item }"
      >
        <status-label
          :small="true"
          :value="item.type"
          :status="typeColor(item.type)"
        />
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.note="{ item }"
      >
        <div class="item-notes">
          <span class="p-4 font-weight-light">{{ item.note }}</span>
        </div>
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.actions="{ item }"
      >
        <div class="d-flex actions-row">
          <template v-if="!hasBlockingActions(item)">
            <v-tooltip
              v-if="item.mode == 'incremental'"
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              offset-overflow
              nudge-bottom="4px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  small
                  @click="$emit('action-button-convert-to-full', item)"
                  ><v-icon>$merge</v-icon></v-btn
                >
              </template>

              <span>{{ $t('button.backup.convertToFull') }}</span>
            </v-tooltip>
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              offset-overflow
              nudge-bottom="4px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  small
                  @click="$emit('action-button-download', item)"
                  ><v-icon>$update</v-icon></v-btn
                >
              </template>

              <span>{{ $t('button.backup.download') }}</span>
            </v-tooltip>
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              offset-overflow
              nudge-bottom="4px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  small
                  @click="$emit('action-button-restore', item)"
                  ><v-icon>$restore</v-icon></v-btn
                >
              </template>

              <span>{{ $t("button.restoreBackup") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="!(usesOwnBackupSystem && item.type !== 'Manual')"
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              offset-overflow
              nudge-bottom="4px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  small
                  @click="$emit('action-button-delete', item)"
                  ><v-icon>$thrash</v-icon></v-btn
                >
              </template>

              <span>{{ $t("button.deleteBackup") }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div class="d-flex align-center">
              <loader
                v-if="hasActionInProgress(item)"
                :noMargins="true"
                :size="16"
                color="primary"
                class="mr-2"
              />
              <v-icon
                v-else-if="hasFailedAction(item)"
                :color="'error'"
                size="20"
                class="mr-1"
                >$alerterror</v-icon
              >
              <b style="white-space: nowrap">
                {{ getActionMessage(item) }}
                <span
                  v-if="hasFailedAction(item)"
                  @click="$emit('action-button-repeat', item)"
                  class="try-again"
                >
                  {{ $t('button.try') }}
                </span>
              </b>
            </div>
          </template>
        </div>
      </template>

      <!-- BACKUPS TABLE RESPONSIVE VIEW -->

      <template
        v-if="$vuetify.breakpoint.lgAndDown"
        v-slot:item="{ item, headers }"
      >
        <backups-table-responsive-item
          :item="item"
          :headers="headers"
          @update:checked="handleSelectItem(item)"
          :checked="checkedItems.includes(item.id)"
          v-on="$listeners"
        />
      </template>

      <template v-slot:no-data>
        <data-iterator-no-results-container
          @clearAllFilters="clearFilters"
          :searchTerm="searchTerm"
          v-if="searchTerm"
        />

        <div
          v-else
          class="d-flex flex-column align-center justify-center mx-auto my-10"
          style="max-width: 560px"
        >
          <backupsTableIllustration/>

          <h4 class="mt-6">{{ $t('message.emptyTable.backups.title') }}</h4>
          <p class="p-1 pt-4 text-center text--body font-weight-light">
            {{ $t('message.emptyTable.backups.description')  }}
          </p>
          <v-btn
            rounded
            elevation="0"
            class="ml-0 mt-2"
            x-large
            color="primary"
            @click="$emit('add-new')"
          >
            <v-icon size="24px">$plus</v-icon>
            {{ $t('button.createBackup') }}
          </v-btn>
        </div>
      </template>

      <template v-slot:footer v-if="!noData">
        <div>
          <data-iterator-footer
            :page="page"
            :numberOfPages="numberOfPages"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="itemsPerPage"
            v-on="$listeners"
          ></data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DataIteratorMixin from "../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../mixins/CustomTableMixin";

import DataIteratorFooter from "../dataIterator/DataIteratorFooter.vue";
import StatusLabel from "../StatusLabel.vue";
import TableCustomSort from "../dataIterator/TableCustomSort.vue";
import Checkbox from "../buttons/Checkbox.vue";
import DataIteratorNoResultsContainer from "../dataIterator/DataIteratorNoResultsContainer.vue";
import BackupsTableResponsiveItem from "./BackupsTableResponsiveItem.vue";

import BackupsTableItemSkeleton from "./BackupsTableItemSkeleton.vue";
import BackupsTableSortSkeleton from "./BackupsTableSortSkeleton.vue";
import BackupsTableItemSkeletonMobile from "./BackupsTableItemSkeletonMobile.vue";
import Loader from "../Loader.vue";
import backupsTableIllustration from "../illustrations/backups-table-illustration.vue";

export default {
  components: {
    DataIteratorFooter,
    StatusLabel,
    TableCustomSort,
    Checkbox,
    DataIteratorNoResultsContainer,
    BackupsTableResponsiveItem,
    BackupsTableItemSkeleton,
    BackupsTableSortSkeleton,
    BackupsTableItemSkeletonMobile,
    Loader,
    backupsTableIllustration,
  },
  props: {
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
    instance: Object
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  methods: {
    clearFilters: function () {
      this.searchTerm = "";
    },
    getActionMessage: function (item) {
      if (this.hasActionInProgress(item)) {
        if (item.async_status.create == "pending" || item.async_status.create == "in_progress") {
          return this.$t('message.status.backup.create.pending');
        }
        if (item.async_status.restore == "pending"|| item.async_status.restore == "in_progress") {
          return this.$t('message.status.backup.restore.pending');
        }
        if (item.async_status.delete == "pending" || item.async_status.delete == "in_progress") {
          return this.$t('message.status.backup.delete.pending');
        }
      }

      if (this.hasFailedAction(item)) {
        if (item.async_status.create == "failed") {
          return this.$t('message.status.backup.create.failed');
        }
        if (item.async_status.restore == "failed") {
          return this.$t('message.status.backup.restore.failed');
        }
        if (item.async_status.delete == "failed") {
          return this.$t('message.status.backup.delete.failed');
        }
      }
      return item;
    },
    hasActionInProgress(item) {
      return (
        item.async_status.create == "pending" ||
        item.async_status.create == "in_progress" ||
        item.async_status.restore == "pending" ||
        item.async_status.restore == "in_progress" ||
        item.async_status.delete == "pending" ||
        item.async_status.delete == "in_progress"
      );
    },
    hasFailedAction(item) {
      return (
        item.async_status.create == "failed" ||
        item.async_status.restore == "failed" ||
        item.async_status.delete == "failed"
      );
    },
    hasBlockingActions: function (item) {
      return this.hasActionInProgress(item) || this.hasFailedAction(item);
    },
    typeColor: function (value) {
      if (value == "Automatic") {
        return "success";
      } else if (value == "Manual") {
        return "purple";
      }
    },
    formatDate: function (value) {
      if (!value) return "---";
      const timestamp = typeof value === "string" ? parseInt(value) : value;
      if (isNaN(timestamp)) return "---";

      const date = new Date(timestamp);
      if (isNaN(date.getTime())) return "---";

      try {
        const locale = navigator.language || navigator.userLanguage || 'en-US';

        const dateFormatter = new Intl.DateTimeFormat(locale, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
        const formattedDate = dateFormatter.format(date);

        const timeFormatter = new Intl.DateTimeFormat(locale, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        const formattedTime = timeFormatter.format(date);

        return `<span class="font-weight-bold">${formattedDate}</span> ${formattedTime}`;
      } catch (error) {
        return "---";
      }
    },
  },
  computed: {
    noData: function () {
      if (!this.items.length && !this.searchTerm) {
        return true;
      } else {
        return false;
      }
    },
    usesOwnBackupSystem() {
      return this.instance.server_settings.uses_own_backup_system;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-notes {
  max-width: 180px;
  width: 180px;
}

.v-data-table::v-deep {
  colgroup {
    col:nth-child(7) {
      width: 180px;
    }
  }
  &.v-data-table--mobile > .v-data-table__wrapper tbody {
    display: contents;
  }
  &.data-table--loading {
    colgroup {
      col {
        width: calc(100% / 6);
      }
    }
  }
  .v-btn {
    margin-left: 10px;
    @media (max-width: 1183px) {
      margin-left: 0px;
      margin-right: 10px;
    }
  }
  .v-data-table__empty-wrapper {
    td {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}

.try-again {
  cursor: pointer;
  color: map-get($error, base);
  transition: color 0.24s ease;
  &:hover {
    color: map-get($primary, base);
  }
}
</style>
