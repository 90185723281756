<template>
  <div>
    <v-container class="page-title">
      <v-row>
        <v-col cols="5" class="page-title__title-box white-space-nowrap">
          <h4 class="page-title__title mr-4">{{ $t('heading.instance.backups.title') }}</h4>
          <div
            v-if="!itemsLoading"
            class="d-flex"
          >
            <backups-usage-item
              v-for="item in usage"
              :key="item.name"
              :item="item"
            ></backups-usage-item>
          </div>
        </v-col>

        <v-col
          class="page-title__filter-controls"
          :class="`${$vuetify.breakpoint.mobile ? 'pb-0' : ''}`"
        >
          <backup-automatic-switcher
            v-if="!usesOwnBackupSystem"
            :automaticBackups="autoBackupSettings"
            :instance="instance"
            :customClass="'mr-4'"
            :loading="automaticBackupsLoading"
            :editable="instance.automatic_backups_editable"
            @reload-settings="reloadAutobackupSettings"
          />

          <data-iterator-filter-controls
            :sortBy="sortBy"
            :sortDesc="sortDesc"
            :keys="keys"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            :fullWidthSearch="false"
            @update:sortBy="changeSortBy"
            @update:sortDesc="handleSortDescChange"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>

          <v-btn
            :large="!$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.smAndDown"
            elevation="0"
            color="primary"
            class="p-2 add-new-button page-title__add-button"
            @click="showCreateModal"
          >
            <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
            >$plus
            </v-icon
            >
            {{ $vuetify.breakpoint.mobile ? "" : $t('button.addNew') }}
          </v-btn>
          <v-dialog
            :persistent="modalOptions.persistent"
            v-model="modalOptions.open"
            ref="dialog"
            transition="custom-dialog-transition"
          >
            <div
              class="card-overlay"
              v-if="!modalOptions.persistent"
              @click="modalOptions.open = !modalOptions.open"
            />
            <div
              class="card-overlay"
              v-else
              @click="$refs.dialog.animateClick()"
            />
            <basic-modal
              ref="basicModal"
              style="width: 560px"
              :modalOptions="modalOptions"
              @modal-close="modalClose"
              :key="modalRender"
              :instance="instance"
            />
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <backups-table
              :checkedItems="checkedItems"
              :headers="computedHeaders"
              :items="items"
              :itemsLoading="itemsLoading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :instance="instance"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              @action-button-download="downloadBackup"
              @action-button-convert-to-full="convertToFullBackup"
              @action-button-restore="showRestoreModal"
              @action-button-delete="showDeleteModal"
              @action-button-repeat="repeatAction"
              @add-new="showCreateModal"
            >
            </backups-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <backups-select-snackbar
      :items="items"
      :checkedItems="checkedItems"
      @update:check-all="handleSelectAll"
      @mass-action-remove="showMassDeleteModal"
    />
  </div>
</template>

<script>
import Api from "@/apis/Api";
import Helper from "@/apis/Helper";

import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import AutomaticBackupsSettingsFetchMixin from "../../mixins/AutomaticBackupsSettingsFetchMixin";
import BackupActionsMixin from "../../mixins/BackupActionsMixin";
import ActionModalMixin from "../../mixins/ActionModalMixin";

import BackupsTable from "../../components/backups/BackupsTable.vue";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import BackupsSelectSnackbar from "../../components/backups/BackupsSelectSnackbar.vue";
import BackupAutomaticSwitcher from "../../components/backups/BackupAutomaticSwitcher.vue";
import BasicModal from "../../components/modal/BasicModal";
import BackupsUsageItem from "../../components/backups/BackupsUsageItem.vue";

export default {
  components: {
    BackupsUsageItem,
    BackupsTable,
    DataIteratorFilterControls,
    BackupsSelectSnackbar,
    BasicModal,
    BackupAutomaticSwitcher,
  },
  mixins: [
    DataIteratorPageMixin,
    CustomTablePageMixin,
    AutomaticBackupsSettingsFetchMixin,
    BackupActionsMixin,
    ActionModalMixin,
  ],
  props: {
    instance: Object,
  },
  watch: {
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  data: function () {
    return {
      sortBy: {
        text: 'date',
        value: "date",
        sortable: true,
      },
      checkedItems: [],
      items: [],
      allItems: [],
      page: 1,
      itemsPerPage: 8,
      itemsLoading: true,
      searchTerm: "",
      itemsTotal: 0,
      fetching: true,
      modalRender: 0,
      modalOptions: {},
      backupsUsage: {}
    };
  },
  mounted() {
    this.reloadBackups();
    this.loadAutoBackupsSettings();
    this.$root.$on("backup-item-changed", (item) => {
      this.instance.setBackupItem(item);
      for (let [key, i] of this.items.entries()) {
        if (i.id === item.id) {
          this.items[key] = this.decorateItem(item);
          // force re-render
          this.items = [...this.items];
          break;
        }
      }
      this.recalculateBackupsUsage();
    });
    this.$root.$on("backup-item-deleted", (item) => {
      this.instance.unsetBackupItem(item);
      for (let [key, i] of this.items.entries()) {
        if (i.id === item.id) {
          this.items.splice(key, 1);
          break;
        }
      }
      this.recalculateBackupsUsage();
    });
  },
  methods: {
    decorateItem(item) {
      return {
        id: item.id,
        name: item.name,
        date: `${item.date}000`,
        size: Helper.formatBytes(item.size),
        size_in_bytes: item.size,
        type: item.type.charAt(0).toUpperCase() + item.type.slice(1),
        mode: item.mode,
        convert_from_id: item.convert_from_id,
        version: item.version,
        note: item.note,
        directory: item.directory,
        database: item.database,
        async_status: item.async_status ? item.async_status : {},
      };
    },
    reloadBackups() {
      this.itemsLoading = true;
      Api.get(`/instances/${this.instance.id}/wordpress/backups`)
        .then((response) => {
          this.instance.setBackupDetails(response.data.data);
          this.allItems = response.data.data.map((item) => {
            return this.decorateItem(item);
          });
          this.loadBackups();
          this.backupsUsage = response.data.meta.usage;
          this.$emit("backups-reload", this.allItems);
        })
        .catch(() => {
          // console.error("ERROR: ", error);
        })
        .finally(() => {
          this.itemsLoading = false;
          this.fetching = false;
        });
    },
    loadBackups() {
      this.itemsLoading = true;
      let items = [...this.allItems];
      // filtering
      if (this.searchTerm) {
        items = items.filter((item) => {
          for (const header of this.computedHeaders) {
            if (header.searchable) {
              if (
                item[header.value]
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())
              ) {
                return true;
              }
            }
          }
          return false;
        });
      }
      this.itemsTotal = items.length;
      // sorting
      items = items.sort((i1, i2) => {
        return (
          i1[this.sortBy.value]
            .toString()
            .localeCompare(i2[this.sortBy.value].toString()) *
          (this.sortDesc ? -1 : 1)
        );
      });
      // paging
      items = items.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
      this.items = items;
      this.itemsLoading = false;
    },
    changePage(page) {
      this.page = page;
      this.loadBackups();
    },
    changePerPage(perPage) {
      this.page = 1;
      this.itemsPerPage = perPage;
      this.loadBackups();
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.sortBy = data;
      this.loadBackups();
    },
    changeSortDesc: function (data) {
      this.page = 1;
      this.sortDesc = data;
      this.loadBackups();
    },
    handleSearchTermChange: function (newValue) {
      this.page = 1;
      this.searchTerm = newValue;
      this.loadBackups();
    },
    recalculateBackupsUsage() {
      const backupsSize = this.items.reduce((accumulator, backup) => {
        return accumulator + backup.size_in_bytes;
      }, 0);

      this.backupsUsage.backupsNumber = this.items.length;
      this.backupsUsage.backupsSize = (backupsSize / 1024 / 1024 / 1024).toFixed(2);
    },
  },
  computed: {
    usesOwnBackupSystem() {
      return this.instance.server_settings.uses_own_backup_system;
    },
    computedHeaders() {
      if (this.instance.server_settings.uses_own_backup_system) {
        return [
          {
            text: "",
            value: "select",
            sortable: false,
          },
          {
            text: 'date',
            value: "date",
            sortable: true,
          },
          {
            text: 'database',
            value: "database",
            sortable: false,
          },
          {
            text: 'directory',
            value: "directory",
            sortable: false,
          },
          {
            text: 'size',
            value: "size",
            sortable: true,
          },
          {
            text: 'type',
            sortable: true,
            value: "type",
          },
          {
            text: 'note',
            sortable: true,
            value: "note",
            searchable: true,
          },
          {
            text: "",
            value: "actions",
            sortable: false,
            align: "end",
          },
        ];
      }

      return [
        {
          text: "",
          value: "select",
          sortable: false,
        },
        {
          text: 'name',
          align: "start",
          value: "name",
          sortable: true,
          searchable: true,
        },
        {
          text: 'date',
          value: "date",
          sortable: true,
        },
        {
          text: 'size',
          value: "size",
          sortable: true,
        },
        {
          text: 'type',
          sortable: true,
          value: "type",
        },
        {
          text: 'version',
          value: "version",
          sortable: true,
        },
        {
          text: 'note',
          sortable: true,
          value: "note",
          searchable: true,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },
    usage() {
      const usage = [];
      for (const [key, value] of Object.entries(this.backupsUsage)) {
        usage.push({
          name: key,
          data: value
        })
      }
      return usage;
    }
  }
};
</script>

<style lang="scss" scoped>
.line-16 {
  line-height: $line-height-xxs;
  height: 16px;
}
</style>
