<template>
      <div class="custom-info-label info--text mr-2">
        {{ totalText }} / {{ maxText }}
        <v-tooltip
          open-delay="150"
          bottom
          transition="custom-tooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              v-bind="attrs"
              size="12"
              class="ml-1 info--text"
            >$infoCircle</v-icon>
          </template>
          {{ $t(`heading.instance.backups.usage.${this.item.name}.tooltip`) }}
        </v-tooltip>
      </div>
</template>

<script>
export default {
  name: "BackupsUsageItem",
  props: {
    item: Object
  },
  computed: {
    totalText() {
      if (this.$i18next.exists(`heading.instance.backups.usage.${this.item.name}.total.suffix`)) {
        return this.item.data.total + ' '+ this.$i18next.t(`heading.instance.backups.usage.${this.item.name}.total.suffix`);
      }

      if (this.$i18next.exists(`heading.instance.backups.usage.${this.item.name}.total.suffix_one`)) {
        return this.item.data.total + ' '+ this.$i18next.t(`heading.instance.backups.usage.${this.item.name}.total.suffix`, {count: this.item.data.total});
      }

      return this.item.data.total;
    },
    maxText() {
      if (this.$i18next.exists(`heading.instance.backups.usage.${this.item.name}.max.suffix`)) {
        return this.item.data.max + ' '+ this.$i18next.t(`heading.instance.backups.usage.${this.item.name}.max.suffix`);
      }

      if (this.$i18next.exists(`heading.instance.backups.usage.${this.item.name}.max.suffix_one`)) {
        return this.item.data.max + ' '+ this.$i18next.t(`heading.instance.backups.usage.${this.item.name}.max.suffix`, {count: this.item.data.max});
      }

      return this.item.data.max;
    }
  }
}
</script>



<style scoped lang="scss">

</style>